<template>
  <div class="relative flex flex-col select-none rounded-md border border-charcoal-200">
    <div class="aspect-[1/1] isolate bg-shell-200 relative flex-center cursor-pointer group rounded-t-md overflow-hidden" @click="emit('item-click')">

      <!--image states-->
      <transition-group name="f-fade">
        <u-icon
          v-if="!src"
          :name="COMMON_ICONS.album"
          class="absolute-center text-charcoal/60 opacity-30 w-24 h-24"
          key="place"
        />
        <span v-else-if="isLoading" class="absolute-center w-16 h-16" key="load">
          <u-icon
            :name="COMMON_ICONS.loading"
            class="text-charcoal/60 opacity-5 w-full h-full animate-spin"
          />
        </span>
        <u-icon
          v-else-if="error"
          :name="COMMON_ICONS.photo"
          class="absolute-center text-charcoal/60 opacity-30 w-16 h-16"
          key="err"
          title="something went wrong when loading this image"
        />
        <div
          v-else
          class="absolute bg-no-repeat bg-center transition-all"
          key="final"
          :style="{backgroundImage: `url(${src})`}"
          :class="[
            selected ? 'inset-2 rounded-lg' : 'inset-0',
            isProject ? 'bg-contain' : 'bg-cover'
          ]"
        />
      </transition-group>

      <div
        class="pointer-events-none absolute inset-0 opacity-0 transition-opacity z-10 duration-300"
        :class="{
        'group-hover:opacity-100 bg-gradient-to-b from-black/15 to-30%': !selectionCandidate && !selected,
        'opacity-100': !selected && !selectionCandidate && hasAny
      }"
      />

      <div
        class="pointer-events-none absolute inset-0 opacity-0 transition-opacity z-20"
        :class="{'opacity-50 bg-blue-300': selectionCandidate}"
      />

      <core-select-toggle v-if="selectable" class="absolute top-1 left-1 z-30" v-model:selected="selected" />

      <div class="absolute z-20 bottom-2 left-2 right-2 flex gap-2">
        <core-list-item-stats :container="album" />
      </div>
    </div>

    <div class="relative flex flex-col flex-1" :class="isNested ? 'p-1.5' : 'p-2.5 pr-7'">
      <div class="flex-1">
        <p class="font-semibold break-long-string cursor-pointer leading-tight text-charcoal line-clamp-3" @click="emit('item-click')" :title="album.name" :class="isNested ? 'text-sm' : 'text-base'">{{album.name}}</p>
        <div class="text-sm text-gray-500" v-if="album.displayDate && !isNested">{{album.displayDate}}</div>
      </div>
      <div class="text-sm mt-1 text-gray-500" v-if="album.sharedItemId">{{album.user?.name}}</div>
      <album-list-item-context-menu class="absolute right-1 top-1.5" :album="album" v-show="!hasAny" v-if="!isNested" />
    </div>

  </div>
</template>

<script setup>
  import {useImage} from '@vueuse/core';

  const emit = defineEmits(['item-click']);
  const props = defineProps({
    album: Object,
    selectable: {
      type: Boolean,
      default: true
    },
    selectionCandidate: Boolean,
    isNested: Boolean
  });

  const selected = defineModel('selected');
  const {hasAny} = useSelection();
  const src = computed(() => props.album.avatarImage);
  const isProject = computed(() => src.value.includes('/projects/'));
  const { isLoading, error } = src.value ? useImage({src: src.value}) : {};
</script>
